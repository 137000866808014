<template>
    <div class="hs3Com1">
        <div class="topSwiper">
            <el-carousel :interval="3000" height="700px" indicator-position="none" :autoplay="false">
                <template v-for="el in getAdvData(24).children">
                    <el-carousel-item :key="el.id" v-if="el.image">
                        <a :href="el.url" target="_blank" rel="noopener noreferrer">
                            <img :src="el.image" alt="" class="elImg" />
                            <img v-for="e in el.children" :key="e.id" :src="e.image" class="elImgs" v-show="e.image" />
                        </a>
                    </el-carousel-item>
                </template>
            </el-carousel>
        </div>
        <div class="hs31Box">
            <!-- 内容一 -->
            <div class="hs31One">
                <!-- 标题 -->
                <div class="hs31oTitle">您是否在为这些事而烦恼？</div>
                <!-- 列表 -->
                <div class="hs31oList">
                    <ul>
                        <li class="hs31Li" @mouseenter="activeIndex = 0" :class="{ activeLi: activeIndex == 0 }">
                            <p class="hs31Big">无关注量</p>
                            <p class="hs31Small">苦苦运营，无关注量，无转化</p>
                        </li>
                        <li class="hs31Li" @mouseenter="activeIndex = 1" :class="{ activeLi: activeIndex == 1 }">
                            <p class="hs31Big">人工成本高</p>
                            <p class="hs31Small">专人运营人工成本高，不找专人太低效</p>
                        </li>
                        <li class="hs31Li" @mouseenter="activeIndex = 2" :class="{ activeLi: activeIndex == 2 }">
                            <p class="hs31Big">无运营方向</p>
                            <p class="hs31Small">毫无经验，运营没有头绪，无从下手</p>
                        </li>
                        <li class="hs31Li" @mouseenter="activeIndex = 3" :class="{ activeLi: activeIndex == 3 }">
                            <p class="hs31Big">无时间资源</p>
                            <p class="hs31Small">企业公众号无内容物介绍，推文枯燥无味，没有时间打理</p>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 内容二 -->
            <div class="hs31Two">
                <img src="http://iv.vu818.com/img/bg1 (1).jpg" alt="" class="hs3Bg" />
                <!-- 标题 -->
                <div class="hs31TTitle">为什么选择我们？</div>

                <div class="hs31TList">
                    <ul>
                        <li class="hs31TLi">
                            <img src="http://iv.vu818.com/img/hc4111.png" alt="" />
                            <div class="hs31LP">
                                <p class="hs31Big">成本低</p>
                                <p class="hs31Small">每月不到一个人的工资价格，提供专业的团队服务</p>
                            </div>
                        </li>
                        <li class="hs31TLi">
                            <div class="hs31LP">
                                <p class="hs31Big">专业性</p>
                                <p class="hs31Small">实体公司，专人负责，完善的售后服务</p>
                            </div>
                            <img src="http://iv.vu818.com/img/hc4112.png" alt="" />
                        </li>
                        <li class="hs31TLi">
                            <img src="http://iv.vu818.com/img/hc4113.png" alt="" />
                            <div class="hs31LP">
                                <p class="hs31Big">效果好</p>
                                <p class="hs31Small">拥有丰富的运营经验，专注微信内容的维护，精心编排全程托管</p>
                            </div>
                        </li>
                        <li class="hs31TLi">
                            <div class="hs31LP">
                                <p class="hs31Big">针对性</p>
                                <p class="hs31Small">精确定位，采取急促数据对运营结果做科学分析</p>
                            </div>
                            <img src="http://iv.vu818.com/img/hc4114.png" alt="" />
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 内容三 -->
            <div class="hs31Three">
                <!-- 标题 -->
                <div class="hs31ThTop">我们能为您做什么？</div>
                <div class="hs31ThList">
                    <ul>
                        <li class="hs31ThLi">
                            <div class="hThImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <div class="hs31ThP">
                                <p>收集素材</p>
                                <p>Collecting material</p>
                            </div>
                        </li>
                        <li class="hs31ThLi">
                            <div class="hThImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <div class="hs31ThP">
                                <p>文案创作</p>
                                <p>Copywriting</p>
                            </div>
                        </li>
                        <li class="hs31ThLi">
                            <div class="hThImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <div class="hs31ThP">
                                <p>文章排版</p>
                                <p>Article layout</p>
                            </div>
                        </li>
                        <li class="hs31ThLi">
                            <div class="hThImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <div class="hs31ThP">
                                <p>图文推送</p>
                                <p>Graphic push</p>
                            </div>
                        </li>
                        <li class="hs31ThLi">
                            <div class="hThImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <div class="hs31ThP">
                                <p>日常运营</p>
                                <p>Daily operation</p>
                            </div>
                        </li>
                        <li class="hs31ThLi">
                            <div class="hThImg">
                                <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                            </div>
                            <div class="hs31ThP">
                                <p>数据分析</p>
                                <p>Data analysis</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HsContent3",
    inject: ["getAdvData"],
    data() {
        return {
            activeIndex: 0,
        };
    },
    components: {},
};
</script>

<style lang="less" scoped>
.hs3Com1 {
    min-width: 1423px;
    overflow: hidden;
    .topSwiper {
        // min-width: 1423px;
        width: 100%;
        height: 600px;
        position: relative;
        .el-carousel {
            width: 100%;
            position: absolute;
            .el-carousel__item {
                width: 100%;
                height: 100%;
                text-align: left;
                .elImg {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    // left: 50%;
                    // transform: translateX(-50%);
                    object-fit: cover;
                }
                .elImgs {
                    width: 500px;
                    height: 300px;
                    position: absolute;
                    top: 25%;
                    left: 10%;
                    animation-name: slideRight;
                    animation-duration: 2s;
                    animation-timing-function: ease-in-out;
                }
                .elImgs:nth-child(3) {
                    width: 350px;
                    height: 300px;
                    position: absolute;
                    top: 15%;
                    left: 65%;
                }
                .elImgs:nth-child(4) {
                    width: 400px;
                    height: 250px;
                    position: absolute;
                    top: 50%;
                    left: 60%;
                }
            }
            @keyframes slideRight {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
                0% {
                    transform: translateX(-150%);
                }
                50% {
                    transform: translateX(8%);
                }
                65% {
                    transform: translateX(-4%);
                }
                80% {
                    transform: translateX(4%);
                }
                95% {
                    transform: translateX(-2%);
                }
                100% {
                    transform: translateX(0);
                }
            }
        }
    }
    .hs31Box {
        width: 1280px;
        height: 1700px;
        margin: 0 auto;
        margin-top: 210px;
        // 内容一
        .hs31One {
            width: 100%;
            height: 600px;
            // 标题
            .hs31oTitle {
                width: 80%;
                height: 50px;
                margin: 0 auto;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
                text-align: center;
            }
            // 列表
            .hs31oList {
                width: 100%;
                height: 450px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .activeLi {
                        background-color: rgb(119 129 241) !important;
                        color: white;
                    }
                    .hs31Li {
                        width: 46%;
                        height: 45%;
                        text-align: center;
                        background-color: white;
                        box-shadow: 3px 3px 10px 3px #eee;
                        border-radius: 20px;
                        .hs31Big {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            font-size: 25px;
                            font-weight: 900;
                            margin-top: 50px;
                        }
                        .hs31Small {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                    // .hs31Li:hover {
                    //     // background: url('https://www.vu818.com/wangzhan/images/35.png') no-repeat center;
                    //     background-color: rgb(119 129 241);
                    //     color: white;
                    // }
                }
            }
        }
        //内容二
        .hs31Two {
            width: 100%;
            height: 600px;
            margin-top: 50px;
            position: relative;
            text-align: left;
            .hs3Bg {
                min-width: 1400px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                object-fit: cover;
            }
            .hs31TTitle {
                width: 50%;
                height: 50px;
                margin: 0 auto;
                line-height: 50px;
                text-align: center;
                font-size: 40px;
                font-weight: 900;
            }
            .hs31TList {
                width: 100%;
                height: 500px;
                margin-top: 20px;
                ul {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    .hs31TLi {
                        width: 350px;
                        height: 110px;
                        .hs31LP {
                            width: 200px;
                            height: 100%;
                            float: left;
                            .hs31Big {
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                font-size: 25px;
                                font-weight: 900;
                            }
                            .hs31Small {
                                width: 100%;
                                height: 60px;
                                line-height: 30px;
                                font-size: 15px;
                            }
                        }
                        img {
                            float: left;
                        }
                    }
                    .hs31TLi:nth-child(1) {
                        position: absolute;
                        top: 0;
                        left: 40px;
                    }
                    .hs31TLi:nth-child(2) {
                        position: absolute;
                        top: 50px;
                        right: 40px;
                    }
                    .hs31TLi:nth-child(3) {
                        position: absolute;
                        bottom: 50px;
                        left: 100px;
                    }
                    .hs31TLi:nth-child(4) {
                        position: absolute;
                        bottom: 80px;
                        right: 80px;
                    }
                }
            }
        }
        // 内容三
        .hs31Three {
            width: 100%;
            height: 400px;
            margin-top: 50px;
            .hs31ThTop {
                width: 50%;
                height: 50px;
                margin: 0 auto;
                line-height: 50px;
                text-align: center;
                font-size: 40px;
                font-weight: 900;
            }
            .hs31ThList {
                width: 100%;
                height: 250px;
                margin-top: 30px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hs31ThLi {
                        width: 180px;
                        border-radius: 20px;
                        text-align: center;
                        background-color: #fff;
                        box-shadow: 3px 3px 10px 3px #eee;
                        &:nth-child(1) {
                            .hThImg {
                                & > img {
                                    top: -979px;
                                    left: -356px;
                                }
                            }
                        }
                        &:nth-child(2) {
                            .hThImg {
                                & > img {
                                    top: -979px;
                                    left: -152px;
                                }
                            }
                        }
                        &:nth-child(3) {
                            .hThImg {
                                & > img {
                                    top: -979px;
                                    left: -303px;
                                }
                            }
                        }
                        &:nth-child(4) {
                            .hThImg {
                                & > img {
                                    top: -979px;
                                    left: -249px;
                                }
                            }
                        }
                        &:nth-child(5) {
                            .hThImg {
                                & > img {
                                    top: -979px;
                                    left: -200px;
                                }
                            }
                        }
                        &:nth-child(6) {
                            .hThImg {
                                & > img {
                                    top: -979px;
                                    left: -409px;
                                }
                            }
                        }
                        .hThImg {
                            width: 46px;
                            height: 43px;
                            margin: 60px auto 0;
                            position: relative;
                            overflow: hidden;
                            & > img {
                                position: absolute;
                            }
                        }
                        .hs31ThP {
                            margin-top: 20px;
                            p:nth-child(1) {
                                font-size: 18px;
                            }
                            p:nth-child(2) {
                                font-size: 13px;
                                color: gray;
                            }
                        }
                    }
                    .hs31ThLi:hover {
                        transform: translateY(-10px);
                        transition: 0.5s;
                    }
                }
            }
        }
    }
}
</style>
